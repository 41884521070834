import React from 'react'

import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom'

import App from './App'
import { __DEV__, appConfig } from 'appConfig'

import messages from 'i18n/locales/en.json'
import compiledMessages from 'i18n/compiled/en.json'
import { createBrowserHistory } from 'history'

import 'styles/index.css'

const history = createBrowserHistory()

Sentry.init({
  dsn: appConfig.sentryDsn,
  environment: appConfig.environment,
  normalizeDepth: 5,
  integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],
  tracesSampleRate: 0.2,
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/lead-tracking\.services\.(prod|stage)\.zoe\.net/,
    /^https:\/\/quiz\.services\.(prod|stage)\.zoe\.net/,
  ],
  ignoreErrors: [
    // GTM/GA errors
    /Can't find variable: (_tfa|twq|ttq|PaymentAutofillConfig)/,
    /(_tfa|twq|ttq) is not defined/,
    // Browser / browser extension errors
    'Extension context invalidated.',
    /.*@webkit-masked-url.*/,
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Ignore Google flakiness
    /\/(gtm|ga|analytics)\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Firefox extensions
    /^resource:\/\//i,
    // Other plugins
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
})

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occured</p>}>
    <React.StrictMode>
      <App locale="en" messages={__DEV__ ? messages : compiledMessages} />
    </React.StrictMode>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// import reportWebVitals from "./reportWebVitals";
// reportWebVitals();
