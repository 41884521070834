import React from 'react'
import { Conditions, MenoscaleConditions, Quiz, QuizBase, V5Conditions } from 'redux/quiz/types'
import * as quizScreens from '../../individual-screens'
import {
  Advice1ScreenProps,
  CardSelectionScreenProps,
  EmailScreenProps,
  ExitScreenProps,
  HealthSummaryScreenProps,
  HeightScreenProps,
  InfoScreenProps,
  LocationSignUpScreenProps,
  MultipleQuestionsScreenProps,
  PregnancyScreenProps,
  ProgressScreenProps,
  ResultsScreenProps,
  SingleQuestionScreenProps,
  SorryToSeeYouGoScreenProps,
  TargetWeightScreenProps,
  TestimonialScreenProps,
  TextInputScreenProps,
  VideoScreenProps,
  WeightScreenProps,
  ZoeCanHelpScreenProps,
} from '../../individual-screens'
import { OnAdviceSubmitCallback, onBackClickCallback, OnFormSubmitCallback, onNextClickCallback } from '../../types'
import { weightPlanTypeFromQuiz } from '../../../libs/weight/weightCalculations'
import { SupportedCountries, SupportedQuizCountriesEnum } from '../../../libs/geoLocation'
import { evaluateCondition } from '../../utils'
import { AltPropsSupport, LegacyQuizScreen, QuizScreen, QuizVersion, ScreenType } from '../../../config/types'
import DEFAULT_QUIZ_CONFIG from '../../../config/quiz.config'
import { goalAnswersToBeSubmitted } from '../../../data/goalAnswersToBeSubmitted'
import { updateScreens } from '../../../experiments'
import { ALL_EXPERIMENTS, ExperimentNames } from '../../../config/experiments.config'
import { MultipleSlidersScreen, MultipleSlidersScreenProps } from '../../core/screens/MultipleSlidersScreen'
import { MenoScaleAboutMeScreen, MenoScaleAboutMeScreenProps } from '../../individual-screens/MenoScaleAboutMeScreen'
import { MenoScaleResultsScreen, MenoScaleResultsScreenProps } from '../../individual-screens/MenoScaleResultsScreen'
import { ConsentScreen, ConsentScreenProps } from '../../core/screens/ConsentScreen'

const supportsAltProps = (screen: any): screen is AltPropsSupport<unknown> => {
  return 'useAltProps' in screen && 'altProps' in screen
}

const getScreenProps = (screen: QuizScreen<unknown>, conditionValues: Required<Conditions>) => {
  if (supportsAltProps(screen)) {
    return evaluateCondition(screen.useAltProps, conditionValues) ? screen.altProps : screen.props
  }
  return screen.props
}

const createComponent = (
  answers: QuizBase,
  conditionValues: Required<Conditions>,
  screen: ScreenType,
  onFormSubmit: OnFormSubmitCallback,
  onAdviceSubmit: OnAdviceSubmitCallback,
  onEmailSubmit: OnFormSubmitCallback,
  onNextClick: onNextClickCallback,
  onBackClick: onBackClickCallback,
): JSX.Element => {
  const props = getScreenProps(screen, conditionValues)
  switch (screen.type) {
    case 'SingleQuestionScreen':
      const sqsProps = props as SingleQuestionScreenProps
      return (
        <quizScreens.SingleQuestionScreen
          onNextClick={onFormSubmit}
          onBackClick={onBackClick}
          conditionValues={conditionValues}
          quiz={answers}
          goalAnswersToBeSubmitted={
            sqsProps.includeWeightGoalAnswers ? goalAnswersToBeSubmitted(answers, 0) : undefined
          }
          {...sqsProps}
        />
      )
    case 'CardSelectionScreen':
      const cssProps = props as CardSelectionScreenProps
      return (
        <quizScreens.CardSelectionScreen
          onNextClick={onFormSubmit}
          onBackClick={onBackClick}
          conditionValues={conditionValues}
          quiz={answers}
          {...cssProps}
        />
      )
    case 'MultipleQuestionsScreen':
      return (
        <quizScreens.MultipleQuestionsScreen
          onNextClick={onFormSubmit}
          onBackClick={onBackClick}
          quiz={answers}
          {...(props as MultipleQuestionsScreenProps)}
        />
      )
    case 'MultipleSlidersScreen':
      return (
        <MultipleSlidersScreen
          onNextClick={onFormSubmit}
          onBackClick={onBackClick}
          quiz={answers}
          {...(props as MultipleSlidersScreenProps)}
        />
      )
    case 'InfoScreen':
      return (
        <quizScreens.InfoScreen
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          quiz={answers}
          {...(props as InfoScreenProps)}
        />
      )
    case 'TextInputScreen':
      return (
        <quizScreens.TextInputScreen
          onNextClick={onFormSubmit}
          onBackClick={onBackClick}
          {...(props as TextInputScreenProps)}
        />
      )
    case 'HeightScreen':
      return (
        <quizScreens.HeightScreen
          onNextClick={onFormSubmit}
          onBackClick={onBackClick}
          {...(props as HeightScreenProps)}
        />
      )
    case 'WeightScreen':
      return (
        <quizScreens.WeightScreen
          onNextClick={onFormSubmit}
          onBackClick={onBackClick}
          quiz={answers}
          {...(props as WeightScreenProps)}
        />
      )
    case 'TargetWeightScreen':
      return (
        <quizScreens.TargetWeightScreen
          onNextClick={onFormSubmit}
          onBackClick={onBackClick}
          quiz={answers}
          {...(props as TargetWeightScreenProps)}
        />
      )
    case 'SorryToSeeYouGoScreen':
      return (
        <quizScreens.SorryToSeeYouGoScreen
          onNextClick={onFormSubmit}
          onBackClick={onBackClick}
          {...(props as SorryToSeeYouGoScreenProps)}
        />
      )
    case 'ResultsScreen':
      return (
        <quizScreens.ResultsScreen
          onNextClick={onFormSubmit}
          onBackClick={onBackClick}
          quiz={answers}
          {...(props as ResultsScreenProps)}
        />
      )
    case 'PregnancyScreen':
      return (
        <quizScreens.PregnancyScreen
          onNextClick={onFormSubmit}
          onBackClick={onBackClick}
          {...(props as PregnancyScreenProps)}
        />
      )
    case 'TestimonialScreen':
      return (
        <quizScreens.TestimonialScreen
          onNextClick={onFormSubmit}
          onBackClick={onBackClick}
          {...(props as TestimonialScreenProps)}
        />
      )
    case 'LocationSignupScreen':
      return (
        <quizScreens.LocationSignUpScreen
          onNextClick={(values) => onEmailSubmit(values, answers)}
          onBackClick={onBackClick}
          {...(props as LocationSignUpScreenProps)}
        />
      )
    case 'ZoeCanHelpScreen':
      return (
        <quizScreens.ZoeCanHelpScreen
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          quiz={answers}
          {...(props as ZoeCanHelpScreenProps)}
        />
      )
    case 'ProgressScreen':
      return (
        <quizScreens.ProgressScreen
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          quiz={answers}
          {...(props as ProgressScreenProps)}
        />
      )
    case 'EmailScreen':
      const emailScreenProps = props as EmailScreenProps
      return (
        <quizScreens.EmailScreenMarketingConsent
          onNextClick={(values) => onEmailSubmit(values, answers, emailScreenProps.kameleoonGoals)}
          onBackClick={onBackClick}
          {...emailScreenProps}
        />
      )
    case 'Advice1Screen':
      return (
        <quizScreens.Advice1Screen
          quiz={answers}
          onNextClick={onAdviceSubmit}
          onBackClick={onBackClick}
          {...(props as Advice1ScreenProps)}
        />
      )
    case 'VideoScreen':
      return (
        <quizScreens.VideoScreen onNextClick={onNextClick} onBackClick={onBackClick} {...(props as VideoScreenProps)} />
      )
    case 'HealthSummaryScreen':
      return (
        <quizScreens.HealthSummaryScreen
          quiz={answers}
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          {...(props as HealthSummaryScreenProps)}
        />
      )
    case 'ConsentScreen':
      return <ConsentScreen onNextClick={onFormSubmit} onBackClick={onBackClick} {...(props as ConsentScreenProps)} />
    case 'ExitScreen':
      return <quizScreens.ExitScreen {...(props as ExitScreenProps)} />
    case 'MenoScaleAboutMeScreen':
      return (
        <MenoScaleAboutMeScreen
          onNextClick={onFormSubmit}
          onBackClick={onBackClick}
          quiz={answers}
          {...(props as MenoScaleAboutMeScreenProps)}
        />
      )
    case 'MenoScaleResultsScreen':
      return (
        <MenoScaleResultsScreen
          onNextClick={onNextClick}
          onBackClick={onBackClick}
          quiz={answers}
          {...(props as MenoScaleResultsScreenProps)}
        />
      )
  }
}

const applyExperimentOverrides = (screens: ScreenType[], experiments: ExperimentNames[]): ScreenType[] => {
  const overrides = experiments
    .map((experimentName) => ALL_EXPERIMENTS[experimentName])
    .filter((overrides) => !!overrides)
    .flat()
  return updateScreens(screens, overrides)
}

export const createQuizScreens = (
  quiz: Quiz,
  quizVersion: QuizVersion,
  experiments: ExperimentNames[],
  quizCountry: string | undefined,
  onFormSubmit: OnFormSubmitCallback,
  onAdviceSubmit: OnAdviceSubmitCallback,
  onEmailSubmit: OnFormSubmitCallback,
  onNextClick: onNextClickCallback,
  onBackClick: onBackClickCallback,
): LegacyQuizScreen[] => {
  const answers = quiz[quizVersion]
  const conditionValues = calculateConditionValues(answers, quizCountry, quizVersion)
  const defaultScreens = DEFAULT_QUIZ_CONFIG[quizVersion].screens
  const updatedScreens = applyExperimentOverrides(defaultScreens, experiments)
  return updatedScreens.map((screen: ScreenType) => {
    return {
      path: screen.path,
      hideProgressBar: screen.hideProgressBar,
      // we ignore conditional screens (showWhen) when calculating the progress bar, to give a better sense of progress
      // we also use true as default when isIncludedInProgress is not defined
      isIncludedInProgress: !screen.showWhen && screen.isIncludedInProgress !== false,
      showIf: (quiz: Quiz) => {
        const recentAnswers = quiz[quizVersion]
        const recentConditionValues = calculateConditionValues(recentAnswers, quizCountry, quizVersion)
        return evaluateCondition(screen.showWhen, recentConditionValues)
      },
      screenComponent: createComponent(
        answers,
        conditionValues,
        screen,
        onFormSubmit,
        onAdviceSubmit,
        onEmailSubmit,
        onNextClick,
        onBackClick,
      ),
    }
  })
}

const calculateConditionValues = (
  quiz: QuizBase,
  quizCountry: string | undefined,
  version: QuizVersion,
): Required<V5Conditions> | Required<MenoscaleConditions> => {
  const supportedQuizCountries = Object.keys(SupportedQuizCountriesEnum)
  const isAllowedQuizCountry = quizCountry ? supportedQuizCountries.includes(quizCountry) : false
  // convert all values from the quiz object to strings
  // this means that even questions that are not defined in the type QuizBase (but are included in the quiz config)
  // can be used in the condition evaluation
  const baseValues = Object.entries(quiz ?? {}).map(([key, value]) => [
    key,
    value ? (Array.isArray(value) ? value : String(value)) : '',
  ])
  if (version === 'menoscale') {
    return {
      ...Object.fromEntries(baseValues),
      quiz_country: quizCountry as SupportedCountries,
    }
  } else {
    const weightPlan = weightPlanTypeFromQuiz(quiz)
    return {
      // all conditions that are part of the quiz object
      ...Object.fromEntries(baseValues),
      // custom conditions below
      weight_plan: weightPlan,
      has_target_date_weight_loss: String(
        quiz.target_event_date_weight_loss && quiz.target_event_date_weight_loss !== 'none',
      ),
      is_frequently_hungry: String(quiz.hunger_level === 'often' || quiz.hunger_level === 'all_the_time'),
      quiz_country: isAllowedQuizCountry ? (quizCountry as SupportedCountries) : 'false',
      is_country_supported: String(isAllowedQuizCountry),
      available_in_location: String(quiz.location === 'true' && isAllowedQuizCountry),
      has_blood_sugar_or_blood_fast_risk: String(
        quiz.diabetes_or_prediabetes === 'true' ||
          quiz.cardiovascular_disease_in_family === 'true' ||
          quiz.cardiovascular_disease_diagnosed_or_high_cholesterol === 'true' ||
          quiz.blood_pressure_diagnosed === 'true' ||
          quiz.blood_pressure_medication === 'true',
      ),
    }
  }
}
