import { QuizConfig } from './types'
import { GOALS } from '../libs/kameleoon'

const menoscale: QuizConfig = {
  screens: [
    {
      path: '/menoscale/about-me',
      type: 'MenoScaleAboutMeScreen',
      props: {
        title: "Let's start with the basics",
        description: 'We ask these questions so we can personalise your results.',
        questions: [
          {
            question: 'What is your menopausal status?',
            questionKey: 'menopause',
            options: [
              { label: 'Pre-menopausal (have not started menopause)', value: 'premenopausal' },
              {
                label: 'Perimenopausal (you may have menopausal symptoms and periods may become irregular)',
                value: 'perimenopausal',
              },
              {
                label:
                  'Post-menopausal (your menstrual periods have stopped for at least one year and have not restarted)',
                value: 'postmenopausal',
              },
              { label: 'Unsure as I do not have regular periods', value: 'do_not_know' },
              { label: 'Prefer not to say', value: 'prefer_not_to_say' },
            ],
          },
          {
            question: 'Have you ever, or are you currently, taking any forms of menopausal hormonal treatment (HRT)?',
            questionKey: 'menopause_hormonal_treatment',
            options: [
              { label: 'I am currently taking HRT', value: 'currently_taking' },
              { label: 'I have taken HRT in the past', value: 'taken_in_past' },
              { label: 'I have never taken HRT', value: 'never_taken' },
            ],
          },
        ],
      },
    },
    {
      path: '/menoscale/1',
      type: 'MultipleSlidersScreen',
      useAltProps: { quiz_country: 'US' },
      props: {
        title: 'To what extent have you been bothered by these as a menopause symptom in the past week?',
        sliderOptionValues: ['0', '1', '3', '5'],
        labels: ['Not at all', 'A little', 'Quite a bit', 'Extremely'],
        requiredFieldMissingMessage: 'Please select an option for this symptom.',
        questions: [
          { question: 'Hot flushes', questionKey: 'hot_flushes' },
          { question: 'Night sweats', questionKey: 'night_sweats' },
          { question: 'Palpitations (heart beating fast, strongly, or irregularly)', questionKey: 'palpitations' },
          { question: 'Joint and muscular discomfort', questionKey: 'joint_and_muscular_discomfort' },
          { question: 'Weight gain', questionKey: 'weight_gain' },
        ],
      },
      altProps: {
        title: 'To what extent have you been bothered by these as a menopause symptom in the past week?',
        sliderOptionValues: ['0', '1', '3', '5'],
        labels: ['Not at all', 'A little', 'Quite a bit', 'Extremely'],
        requiredFieldMissingMessage: 'Please select an option for this symptom.',
        questions: [
          { question: 'Hot flashes', questionKey: 'hot_flushes' },
          { question: 'Night sweats', questionKey: 'night_sweats' },
          { question: 'Palpitations (heart beating fast, strongly, or irregularly)', questionKey: 'palpitations' },
          { question: 'Joint and muscular discomfort', questionKey: 'joint_and_muscular_discomfort' },
          { question: 'Weight gain', questionKey: 'weight_gain' },
        ],
      },
    },
    {
      path: '/menoscale/2',
      type: 'MultipleSlidersScreen',
      props: {
        title: 'To what extent have you been bothered by these as a menopause symptom in the past week?',
        sliderOptionValues: ['0', '1', '3', '5'],
        labels: ['Not at all', 'A little', 'Quite a bit', 'Extremely'],
        requiredFieldMissingMessage: 'Please select an option for this symptom.',
        questions: [
          { question: 'Loss of interest in sex', questionKey: 'loss_of_interest_in_sex' },
          { question: 'Bladder problems (e.g. infections, leaks, urgency)', questionKey: 'bladder_problems' },
          { question: 'Vaginal dryness', questionKey: 'vaginal_dryness' },
          { question: 'Declining skin quality', questionKey: 'declining_skin_quality' },
          { question: 'Declining hair quality', questionKey: 'declining_hair_quality' },
        ],
      },
    },
    {
      path: '/menoscale/3',
      type: 'MultipleSlidersScreen',
      props: {
        title: 'To what extent have you been bothered by these as a menopause symptom in the past week?',
        sliderOptionValues: ['0', '1', '3', '5'],
        labels: ['Not at all', 'A little', 'Quite a bit', 'Extremely'],
        requiredFieldMissingMessage: 'Please select an option for this symptom.',
        questions: [
          { question: 'Irritability', questionKey: 'irritability' },
          { question: 'Low mood, depression, mood swings', questionKey: 'low_mood_depression_mood_swings' },
          { question: 'Anxiety', questionKey: 'anxiety' },
          { question: 'Sleep problems', questionKey: 'sleep_problems' },
          { question: 'Headaches', questionKey: 'headaches' },
        ],
      },
    },
    {
      path: '/menoscale/4',
      type: 'MultipleSlidersScreen',
      props: {
        title: 'To what extent have you been bothered by these as a menopause symptom in the past week?',
        sliderOptionValues: ['0', '1', '3', '5'],
        labels: ['Not at all', 'A little', 'Quite a bit', 'Extremely'],
        requiredFieldMissingMessage: 'Please select an option for this symptom.',
        questions: [
          { question: 'Brain fog', questionKey: 'brain_fog' },
          { question: 'Memory problems', questionKey: 'memory_problems' },
          { question: 'Tiredness and fatigue', questionKey: 'tiredness_and_fatigue' },
          { question: 'Decreased physical strength / stamina', questionKey: 'decreased_physical_strength' },
          { question: 'Digestive symptoms (e.g. bloating, gas, discomfort)', questionKey: 'digestive_symptoms' },
        ],
      },
    },
    {
      path: '/menoscale/research',
      type: 'ConsentScreen',
      props: {
        title: 'Be part of our research',
        questionKey: 'menoscale_science_consent',
        description:
          'We would appreciate it if you could contribute the information you provided when using the MenoScale calculator to ongoing health research. ' +
          'It could, for example, help advance the crucial yet often under-researched field of menopause.',
        sections: [
          {
            title: 'What we will do with your information',
            description:
              'If you give your consent, then we may use it as part of our non-commercial and commercial research. For that purpose we might:{br}',
            items: [
              'Combine it with other data about you if you have shared it with us.',
              'Share it with other researchers.',
              'Publish it anonymously so that our research is transparent.',
              'Offer you the chance to participate in further research, for example if we want to find people who have answered in a particular way.',
            ],
            footer:
              'We will not use or share your data for any other purpose. If we share or publish any of your data, we will make sure there is no information that can identify you in the information we share.',
          },
          {
            title: 'Sharing',
            description:
              'Your consent is completely optional (you will get the same score either way). You may withdraw your consent at any time. For more information see our {menoscaleFaq}.',
          },
        ],
        acceptButtonLabel: 'I consent',
        declineButtonLabel: 'I do not consent',
      },
    },
    {
      path: '/menoscale/email',
      type: 'EmailScreen',
      props: {
        title: 'Almost there...',
        description:
          "Enter your email to discover your MenoScale score and how your symptoms compare to other women’s symptoms. We'll also send you our Menopause Nutrition Guide and a one-off explainer about ZOE.",
        kameleoonGoals: {
          emailSubmitted: GOALS.MENOSCALE_EMAIL_SUBMITTED,
          emailSubmittedWithMarketingOptOut: GOALS.MENOSCALE_EMAIL_SUBMITTED_WITH_MARKETING_OPT_OUT,
          emailSubmittedWithMarketingConsent: GOALS.MENOSCALE_EMAIL_SUBMITTED_WITH_MARKETING_CONSENT,
        },
        topImage: '/img/menoscale/person-using-menoscale.png',
        optInCopy: 'We’d love to send you ongoing science and nutrition emails, news, and offers. Sounds good?',
        topImageAlt: 'Person using MenoScale',
        crmTag: 'menoscale',
        buttonText: 'Continue',
        footerBody:
          'See our <b>{privacyPolicy}</b> to learn how we protect your data under strict European privacy laws.',
        showMarketingConsent: true,
      },
      altProps: {
        title: 'Almost there...',
        description:
          "Enter your email to discover your MenoScale score and how your symptoms compare to other women’s symptoms. We'll also send you our Menopause Nutrition Guide and a one-off explainer about ZOE.",
        buttonText: 'Continue',
        topImage: '/img/menoscale/person-using-menoscale.png',
        kameleoonGoals: {
          emailSubmitted: GOALS.QUIZ_EMAIL_SUBMITTED,
          emailSubmittedWithMarketingOptOut: GOALS.QUIZ_EMAIL_SUBMITTED_WITH_MARKETING_OPT_OUT,
          emailSubmittedWithMarketingConsent: GOALS.QUIZ_EMAIL_SUBMITTED_WITH_MARKETING_CONSENT,
        },
        footerBody:
          'You will also receive ongoing science and nutrition emails, discounts, and offers.{br}{br}' +
          'See our <b>{privacyPolicy}</b> to learn how we protect your data.',
        showMarketingConsent: false,
        defaultMarketingOptIn: true,
        copyright: '©2024 ZOE US Inc.',
      },
      useAltProps: { quiz_country: 'US' },
    },
    {
      path: '/menoscale/results',
      type: 'MenoScaleResultsScreen',
      hideProgressBar: true,
      props: {
        title: '',
        description:
          'Your score means that something is better than something else, but something can also be worse than something else.{br}{br}' +
          'This means that something is better than something else, but something can also be worse than something else.',
      },
    },
  ],
}

export default menoscale
