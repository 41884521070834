import * as Sentry from '@sentry/react'
import { AppDispatch } from 'App'
import { appConfig, GTM_ZOE_DATALAYER } from 'appConfig'
import { RegistrationRequest, RegistrationRequestCrmTagEnum } from 'clients/quiz-service'
import {
  COOKIE_DOMAIN_MAP,
  QUIZ_COUNTRY_COOKIE_NAME,
  QUIZ_EMAIL_COOKIE_NAME,
  QUIZ_ID_COOKIE_EXPIRY_DAYS,
  QUIZ_ID_COOKIE_NAME,
  QUIZ_VERSION_COOKIE_NAME,
  STAGE_COOKIE_DOMAIN,
} from 'components/constants'
import { questionAnsweredDataLayer, quizStartDataLayer, signupEventDataLayer } from 'components/gtmV2'
import { EntryScreen } from 'components/screen-templates/EntryScreen'
import { FormValues } from 'components/screen-templates/FormScreen'
import { formValuesToAnswers } from 'components/utils'
import { useQuizService } from 'contexts/QuizServiceContext'
import { useZoeLead } from 'contexts/ZoeLeadContext'
import queryString from 'query-string'
import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import TagManager from 'react-gtm-module'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useLocation } from 'react-router-dom'
import {
  Answer,
  selectQuiz,
  selectQuizCountry,
  submitAnswers,
  updateQuiz,
  UpdateQuizAction,
  updateQuizCountry,
} from 'redux/quiz/slice'
import { Menoscale, QuizBase, Quizzes } from 'redux/quiz/types'
import { UAParser } from 'ua-parser-js'
import { geoQuizCountry, SupportedCountries, SupportedQuizCountriesEnum } from '../../../libs/geoLocation'
import { HeaderBar } from '../../screen-components/HeaderBar'
import { createQuizScreens } from './createQuizScreens'
import { useNavigator } from './useNavigator'
import { LegacyQuizScreen, QuizVersion } from '../../../config/types'
import { KAMELEOON_QUEUE_NAME } from '../../../libs/kameleoon'
import { KameleoonEmailGoalsIds } from '../../individual-screens'
import {
  calculateMenoscaleScore,
  getMenoscaleMainSymptoms,
  getNormalisedMenoscaleSymptoms,
} from '../../../libs/menoscale/utils'
import { useExperiments } from '../../../contexts/ExperimentContext'

const cookieExpiryDate = new Date(Date.now() + QUIZ_ID_COOKIE_EXPIRY_DAYS * 24 * 60 * 60 * 1000)
const COOKIE_CONSENT_NAMES = ['CookieConsent', 'gtmConsent', 'cookieconsent_status']

const hasCookieConsent = (cookies: { [key: string]: string }) => {
  return COOKIE_CONSENT_NAMES.some((cookieName) => cookies[cookieName] === 'true' || cookies[cookieName] === 'allow')
}

const sanitisedAnswer = (answer: Answer) => {
  return Array.isArray(answer.answer) ? answer.answer.toString() : answer.answer
}

const SentryRoute = Sentry.withSentryRouting(Route)

type MenoscaleData = {
  menoscaleScienceConsent: boolean
  menoscaleScore: number
  menoscaleMainSymptoms: string[]
  answers: Answer[]
}

export const ScreenRoutes: React.FC = () => {
  const [cookies, setCookie] = useCookies([QUIZ_COUNTRY_COOKIE_NAME, QUIZ_ID_COOKIE_NAME, 'ENABLE_QUIZ_DEV_TOOLS'])
  const location = useLocation()
  const quizVersion: QuizVersion = location.pathname.startsWith('/menoscale') ? 'menoscale' : 'v5'
  const { userApi, quizApi } = useQuizService()
  const dispatch = useDispatch<AppDispatch>()
  const quiz = useSelector(selectQuiz)
  const quizCountry = useSelector(selectQuizCountry)
  const { getActiveExperiments } = useExperiments()

  const cookieDomain =
    COOKIE_DOMAIN_MAP[window.location.hostname as keyof typeof COOKIE_DOMAIN_MAP] || STAGE_COOKIE_DOMAIN

  const { trackLead } = useZoeLead()

  const dispatchQuizCountryUpdate = (country: SupportedCountries) => {
    dispatch(updateQuizCountry({ quiz_country: country }))

    setCookie(QUIZ_COUNTRY_COOKIE_NAME, country, {
      path: '/',
      expires: cookieExpiryDate,
      domain: cookieDomain,
    })
  }

  const setQuizCountry = async () => {
    const country = await geoQuizCountry(cookies[QUIZ_COUNTRY_COOKIE_NAME])

    if (!country) {
      return
    }

    if (quizVersion === 'menoscale' || country in SupportedQuizCountriesEnum) {
      dispatchQuizCountryUpdate(country)
    } else {
      window.location.href = `${appConfig.zoeWebsiteBaseUri}/signup`
    }
  }

  const updateQuizAnswersFromBackend = async (userId: string) => {
    const quizResponse = await quizApi.getQuiz(userId)
    const quizResponseAnswers = Object.entries(quizResponse.data.answers)
    const answers = Object.fromEntries(
      quizResponseAnswers
        .filter(([key]) => key !== 'weight' && key !== 'ideal_weight')
        .map(([key, value]) => {
          return [key, value]
        }),
    )

    // TODO remove this after migrating weight/ideal_weight to weight_lbs/ideal_weight_lbs.
    if (quizResponseAnswers.length !== answers.length) {
      const legacyWeight = quizResponseAnswers.find(([key]) => key == 'weight')
      const weightLbs = quizResponseAnswers.find(([key]) => key == 'weight_lbs')
      const weightKg = quizResponseAnswers.find(([key]) => key == 'weight_kg')
      const legacyIdealWeight = quizResponseAnswers.find(([key]) => key == 'ideal_weight')
      const idealWeightLbs = quizResponseAnswers.find(([key]) => key == 'ideal_weight_lbs')
      const idealWeightKg = quizResponseAnswers.find(([key]) => key == 'ideal_weight_kg')

      if (!weightLbs && !weightKg && legacyWeight) {
        answers.weight_lbs = legacyWeight[1]
        answers.weight_unit = 'lbs'
      }

      if (!idealWeightLbs && !idealWeightKg && legacyIdealWeight) {
        answers.ideal_weight_lbs = legacyIdealWeight[1]
      }
    }

    const answersUpdate: UpdateQuizAction = {
      userId: userId,
      version: quizVersion,
      quiz: answers,
    }

    await dispatch(updateQuiz(answersUpdate))

    if (!quizResponse.data.quiz_country) {
      await setQuizCountry()
    } else {
      if (quizVersion === 'menoscale' || quizResponse.data.quiz_country in SupportedQuizCountriesEnum) {
        dispatchQuizCountryUpdate(quizResponse.data.quiz_country as SupportedCountries)
      } else {
        window.location.href = `${appConfig.zoeWebsiteBaseUri}/signup`
      }
    }
  }

  useEffect(() => {
    const { user_id: urlUserId } = queryString.parse(location.search)
    const cookieUserId = cookies[QUIZ_ID_COOKIE_NAME]

    const userId = typeof urlUserId === 'string' ? urlUserId : cookieUserId

    if (quizVersion !== 'menoscale') {
      trackLead({
        eventName: 'QuizFlowStarted',
        quizId: quiz.id,
        cookieConsent: hasCookieConsent(cookies),
      })
    }

    TagManager.dataLayer({
      dataLayer: quizStartDataLayer(quizVersion),
      dataLayerName: GTM_ZOE_DATALAYER,
    })

    if (!userId) {
      setQuizCountry()
      return
    }

    updateQuizAnswersFromBackend(userId)
      .then(() => {
        setCookie(QUIZ_ID_COOKIE_NAME, userId, { path: '/', expires: cookieExpiryDate, domain: cookieDomain })
      })
      .catch((e) => {
        console.log(`Error: failed to retrieve quiz answers for ${userId}`)
        Sentry.captureException(e)
      })

    // Additional guard to redirect people that has done menoscale and goes to the quiz right after
    if (quizVersion !== 'menoscale' && quiz.quiz_country && !(quiz.quiz_country in SupportedQuizCountriesEnum)) {
      window.location.href = `${appConfig.zoeWebsiteBaseUri}/signup`
    }
  }, [])

  const onFormSubmit = async (values?: FormValues, goalAnswers?: Answer[]) => {
    if (values) {
      let answers = formValuesToAnswers(values)
      if (goalAnswers) {
        answers = [...answers, ...goalAnswers]
      }

      const { payload: newerQuiz } = await dispatch(submitAnswers({ quiz_version: quizVersion, answers }))

      // Send all answers to new datalayer
      answers.forEach((answer) => {
        TagManager.dataLayer({
          dataLayer: questionAnsweredDataLayer(quizVersion, answer.question_key, sanitisedAnswer(answer)),
          dataLayerName: GTM_ZOE_DATALAYER,
        })
      })

      // Needed as there was some kind of race condition that meant we were not using
      // latest quiz answer to calculate next() route
      next(newerQuiz)
    } else {
      next()
    }
  }

  const onAdviceSubmit = (goalAnswers?: Answer[]): void => {
    if (goalAnswers) {
      dispatch(submitAnswers({ quiz_version: quizVersion, answers: [...goalAnswers] }))
    }

    next()
  }

  const registerUser = async (
    email: string,
    marketingConsent: boolean,
    tagId?: string,
    crmTag?: string,
    healthGoal?: string,
    menoscaleScienceConsent?: boolean,
    menoscaleScore?: number,
    menoscaleMainSymptoms?: string[],
  ) => {
    const registrationData: RegistrationRequest = {
      id: quiz.id,
      email: email,
      quiz_version: quizVersion,
      marketing_consent: marketingConsent,
      health_goal: healthGoal,
      menoscale_science_consent: menoscaleScienceConsent,
      menoscale_score: menoscaleScore,
      menoscale_main_symptoms: menoscaleMainSymptoms,
    }
    if (tagId) {
      registrationData['tag_id'] = tagId
    }

    if (crmTag) {
      registrationData['crm_tag'] = crmTag as RegistrationRequestCrmTagEnum
    }

    await userApi.register(registrationData)

    setCookie(QUIZ_ID_COOKIE_NAME, quiz.id, { path: '/', expires: cookieExpiryDate, domain: cookieDomain })
    setCookie(QUIZ_VERSION_COOKIE_NAME, quizVersion, { path: '/', expires: cookieExpiryDate, domain: cookieDomain })
    setCookie(QUIZ_EMAIL_COOKIE_NAME, email, { path: '/', expires: cookieExpiryDate, domain: cookieDomain })
  }

  const storeUserDeviceOS = async (userAgentString: string) => {
    const user_device_os = UAParser(userAgentString).os.name
    if (!user_device_os) {
      throw "Couldn't determine user device OS"
    }
    dispatch(
      submitAnswers({
        quiz_version: quizVersion,
        answers: [{ question_key: 'user_device_os', answer: user_device_os }],
      }),
    )
  }

  const hasScienceOptIn = (quiz: QuizBase): quiz is Menoscale => 'menoscale_science_consent' in quiz

  const submitMenoscaleAnswers = async (quizAnswers: Menoscale): Promise<MenoscaleData> => {
    const menoscaleScienceConsent = String(quizAnswers.menoscale_science_consent) === 'true'
    const menoscaleScore = calculateMenoscaleScore(quizAnswers as Menoscale)
    const menoscaleMainSymptoms = getMenoscaleMainSymptoms(quizAnswers as Menoscale).map((symptom) => symptom.name)
    const answers: Answer[] = [
      { question_key: 'menoscale_score', answer: String(menoscaleScore) },
      { question_key: 'menoscale_science_consent', answer: String(menoscaleScienceConsent) },
      { question_key: 'menoscale_main_symptoms', answer: menoscaleMainSymptoms },
    ]
    await dispatch(submitAnswers({ quiz_version: quizVersion, answers }))

    return { menoscaleScienceConsent, menoscaleScore, menoscaleMainSymptoms, answers }
  }
  const onEmailSubmit = async (values: FormValues, quizAnswers: Quizzes, goals: KameleoonEmailGoalsIds) => {
    if (values) {
      try {
        let menoscaleData: MenoscaleData | null = null
        // we check if the menoscale_science_consent field to cast the quizAnswers to Menoscale
        if (quizVersion === 'menoscale' && hasScienceOptIn(quizAnswers)) {
          menoscaleData = await submitMenoscaleAnswers(quizAnswers)
          menoscaleData.answers?.forEach((answer) => {
            TagManager.dataLayer({
              dataLayer: questionAnsweredDataLayer(quizVersion, answer.question_key, sanitisedAnswer(answer)),
              dataLayerName: GTM_ZOE_DATALAYER,
            })
          })
        }

        const marketingConsent = values.marketingOptOut ? false : !!values.marketingOptIn

        await registerUser(
          values.email,
          marketingConsent,
          values.tagId,
          values.crmTag,
          quizAnswers?.health_goal,
          menoscaleData?.menoscaleScienceConsent,
          menoscaleData?.menoscaleScore,
          getNormalisedMenoscaleSymptoms(menoscaleData?.menoscaleMainSymptoms, quizCountry),
        )
      } catch (error) {
        console.error('Could not register subscriber: ', error)
      }

      if (quizVersion !== 'menoscale') {
        // Set quizId and quizEmail explicitly rather than relying on cookies in case registerUser fails and cookies are not set
        trackLead({
          eventName: 'QuizEmailSubmitted',
          quizId: quiz.id,
          quizEmail: values.email,
          cookieConsent: hasCookieConsent(cookies),
        })
      }

      TagManager.dataLayer({
        dataLayer: signupEventDataLayer(
          quizVersion === 'menoscale' ? 'menoscale' : 'quiz',
          quizVersion,
          values.email,
          values.marketingOptIn ? 'granted' : 'denied',
          values.crmTag,
        ),
        dataLayerName: GTM_ZOE_DATALAYER,
      })

      const kameleoonQueue = window[KAMELEOON_QUEUE_NAME]
      if (kameleoonQueue) {
        kameleoonQueue.push(['Goals.processConversion', goals.emailSubmitted])

        const goal = values.marketingOptIn
          ? goals.emailSubmittedWithMarketingConsent
          : goals.emailSubmittedWithMarketingOptOut

        kameleoonQueue.push(['Goals.processConversion', goal])
      }

      try {
        await storeUserDeviceOS(window.navigator.userAgent)
      } catch (error) {
        console.error(`Failed storing user device OS: ${error}`)
      }
    }
    next()
  }

  const onNextClick = () => next()
  const onBackClick = () => previous()

  // removed useMemo wrapper as React was complaining of hooks within hooks
  const _screens: LegacyQuizScreen[] = createQuizScreens(
    quiz,
    quizVersion,
    getActiveExperiments(),
    quizCountry,
    onFormSubmit,
    onAdviceSubmit,
    onEmailSubmit,
    onNextClick,
    onBackClick,
  )

  const { previous, next, currentPageIndex, hideProgressBar } = useNavigator(_screens, quiz)

  const renderScreens = (): JSX.Element[] => {
    return _screens.map((screen) => {
      return <Route key={screen.path} path={screen.path} render={() => screen.screenComponent} />
    })
  }
  return (
    <>
      {quizCountry && (
        <div className="min-h-screen flex flex-col">
          <SentryRoute
            path={`/${quizVersion}`}
            render={() => (
              <>
                <HeaderBar
                  quizVersion={quizVersion}
                  currentPageIndex={currentPageIndex}
                  hideProgressBar={hideProgressBar}
                  screens={_screens}
                />
                {cookies.ENABLE_QUIZ_DEV_TOOLS === 'true' && (
                  <pre className="dbg-zone">{JSON.stringify(quiz, null, 2)}</pre>
                )}
              </>
            )}
          />
          <Switch>
            {renderScreens()}
            <SentryRoute
              key="fallback"
              path="/"
              render={(props) => <EntryScreen to={_screens[0].path} quizVersion={quizVersion} {...props} />}
            />
          </Switch>
        </div>
      )}
    </>
  )
}
