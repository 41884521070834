import React from 'react'
import { useField, useFormikContext } from 'formik'

export interface RadioButtonProps extends React.ComponentProps<'input'> {
  label?: React.ReactNode
  name: string // needed for useField(props)
  clicked?: any // this doesn't seem right
}

export const RadioButton: React.FC<RadioButtonProps> = ({ clicked, ...props }) => {
  const [field, , helpers] = useField(props)
  const context = useFormikContext()
  const { setValue } = helpers

  const clickHandler = async () => {
    setValue(props.value)

    await context.validateForm({
      ...(context.values as any),
      [props.name]: props.value, // ensures validation with the new value
    })

    clicked ? clicked(props.value) : null
  }

  return (
    <div className={`flex-1 highlight-label-selected mb-4 ${props.className ?? ''}`}>
      <input
        type="radio"
        {...field}
        checked={field.value === props.value}
        className={`fixed pointer-events-none opacity-0 ${
          !field.value && field.value !== props.value ? 'indeterminate' : ''
        }`}
        onClick={clickHandler}
        data-testid={`${props.name}-${props.value}-btn`}
      />

      <label
        className="block p-4 border-solid border-2 border-background-secondary bg-background-secondary hover:border-zoe-primary"
        htmlFor={props.name}
        onClick={clickHandler}
        data-testid={`${props.name}-${props.value}-label`}
      >
        {props.label || props.value}
      </label>
    </div>
  )
}
