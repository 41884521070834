import React from 'react'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { ContextAwareText } from '../core/text/ContextAwareText'
import { RadioButton } from '../core/fields/RadioButton'
import * as Yup from 'yup'
import { FormScreen } from '../screen-templates/FormScreen'
import { Menoscale, Quizzes } from '../../redux/quiz/types'
import { DSSpacer } from '@zoe/ds-web'
import { CustomAgeInput } from '../core/fields/CustomAgeInput'

type Question = {
  question: string
  questionKey: keyof Menoscale
  options: Option[]
}

type Option = {
  label: string
  value: string
}

export type MenoScaleAboutMeScreenProps = {
  title: string
  description: string
  questions: Question[]
}

type Props = MenoScaleAboutMeScreenProps & {
  onNextClick: onNextClickType
  onBackClick: onBackClickCallback
  quiz: Quizzes
}

export const MenoScaleAboutMeScreen: React.FC<Props> = (props: Props) => {
  const formBody = props.questions.map((question, index) => (
    <React.Fragment key={index}>
      <div className="mb-6 mt-6">
        <ContextAwareText text={question.question} quiz={props.quiz} />
      </div>
      {question.options.map((option, optionIndex) => (
        <React.Fragment key={optionIndex}>
          <RadioButton
            key={optionIndex}
            name={question.questionKey}
            id={option.value}
            value={option.value}
            label={option.label}
          />
          {optionIndex < question.options.length - 1 && <div className="mx-1" />}
        </React.Fragment>
      ))}
    </React.Fragment>
  ))

  const validationSchemaEntries = props.questions.map((question) => [question.questionKey, Yup.string().required()])

  return (
    <FormScreen
      screenTitle={props.title}
      description={props.description}
      buttonText="Continue"
      form={{
        body: (
          <>
            <div>Your age</div>
            <DSSpacer size={12} direction="vertical" />
            <CustomAgeInput name="age" />
            {formBody}
          </>
        ),
        initialValues: {
          age: '',
        },
        validationSchema: Yup.object({
          ...Object.fromEntries(validationSchemaEntries),
          age: Yup.number()
            .typeError('Age must be a number')
            .min(18, 'Age must be at least 18')
            .max(120, 'Age must be at most 120'),
        }),
      }}
      onNextClick={props.onNextClick}
    />
  )
}
